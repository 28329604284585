import React, { useEffect } from 'react'
import { NoIndex } from '../../components/NoIndex'

import CovidHero from '../../images/conditions/long-covid/long-covid-hero.png'

// Long COVID Components
import LongCovidStats from '../../components/Conditions/LongCovid/LongCovidStats'
import LongCovidSymptoms from '../../components/Conditions/LongCovid/LongCovidSymptoms'
import LongCovidIntegrative from '../../components/Conditions/LongCovid/LongCovidIntegrative'
import LongCovidTreatment from '../../components/Conditions/LongCovid/LongCovidTreatment'
import LongCovidProgram from '../../components/Conditions/LongCovid/LongCovidProgram'
import LongCovidArticles from '../../components/Conditions/LongCovid/LongCovidArticles'
import LongCovidCitations from '../../components/Conditions/LongCovid/LongCovidCitations'

// Common Components
import ConditionHero from '../../components/Conditions/Common/ConditionHero'

import { useEmployerContext } from '../../contexts/Employer/employerProvider'
import VideoSection from '../../components/Conditions/Common/VideoSection'

const BethIsraelEnrollment = ({
  pageContext: { slug: employerSlug }
}) => {
  const { setEmployerSlug, slug } = useEmployerContext()

  useEffect(() => {
    setEmployerSlug(employerSlug)
  }, [employerSlug, setEmployerSlug])

  return (
    <>
      <NoIndex />

      {/* Hero */}
      <ConditionHero
        headline="Whole-person care for Long COVID relief."
        description="Your doctor has referred you to Goodpath for integrative treatments that follow CDC guidelines for long COVID care."
        ctaText="Get started"
        ctaLink={`/signup?e=${slug}&condition=long-covid`}
        image={CovidHero}
        alt="Long COVID."
      />

      {/* Video */}
      <VideoSection title={'How Goodpath approaches Long COVID care'} />

      {/* Stats */}
      <LongCovidStats />

      {/* Symptoms */}
      <LongCovidSymptoms />

      {/* Integrative */}
      <LongCovidIntegrative />

      {/* Treatment */}
      <LongCovidTreatment
        ctaLink={`/signup?e=${slug}&condition=long-covid`}
      />

      {/* Mega section, box, app, coach */}
      <LongCovidProgram />

      {/* Learn more */}
      <LongCovidArticles />

      {/* Citations */}
      <LongCovidCitations />
    </>
  )
}

export default BethIsraelEnrollment
